import Cookies from 'js-cookie'
import Swiper from 'swiper/swiper-bundle';


$(document).on("turbolinks:load",function () {
    $(".datepicker-autoclose").datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true

    })

    $(".plan-selector-user").on("change", function(e){
        let plan_id = e.currentTarget.value
        Turbolinks.visit(window.location.pathname + "?plan_id=" + plan_id, { action: "replace" })
    })


    const swiper = new Swiper('.swiper-general', {
        direction: 'horizontal',

        pagination: {
            el: '.swiper-pagination-general',
        },   
    });
    const swiperVideo = new Swiper('.video-swiper', {
        direction: 'horizontal',
        nested: true,
        slidesPerView: 1,
        pagination: {
            el: '.swiper-video-pagination',
        },   
        navigation: {
            nextEl: '.swiper-button-next-video',
            prevEl: '.swiper-button-prev-video',
            },
    });
      
    console.log("custom")
    const element = document.getElementById("active");
    if(element){
        element.scrollIntoView({block: "start", inline: "center"});    
    }
    
    let sidebar = document.querySelector(".popular-scroll");
    let storedScrollPosition = localStorage.getItem("sidebarScroll");

    if (storedScrollPosition !== null && storedScrollPosition !== "") {
      if(sidebar){
        sidebar.scrollLeft = Number(storedScrollPosition);  
      }
      
    }

    window.addEventListener("turbolinks:before-render", () => {
        if(sidebar){
            localStorage.setItem("sidebarScroll", sidebar.scrollLeft);    
        }
    });

    let sidebar2 = document.querySelector(".news-scroll");
    let storedScrollPosition2 = localStorage.getItem("sidebarScroll2");

    if (storedScrollPosition2 !== null && storedScrollPosition2 !== "") {
      if(sidebar2){
        sidebar2.scrollLeft = Number(storedScrollPosition2);  
      }
      
    }

    window.addEventListener("turbolinks:before-render", () => {
        if(sidebar2){
            localStorage.setItem("sidebarScroll2", sidebar2.scrollLeft);    
        }
    });

    window.addEventListener("turbolinks:before-render", () => {
      $("#categoriesModal").hide()
      $(".modal-backdrop").hide()
      $(".hs").css("scroll-behavior", "")
    });

    
    
    setTimeout(function(){
        $(".hs").css("scroll-behavior", "smooth")
    },200)
    

    $(".right-arrow").on("click",function(e){
        scroll = $(e.currentTarget).parent().scrollLeft()
        $(e.currentTarget).parent().scrollLeft(scroll + 800)
    })

    $(".left-arrow").on("click",function(e){
        scroll = $(e.currentTarget).parent().scrollLeft()
        $(e.currentTarget).parent().scrollLeft(scroll - 800)
    })

    $(".popular-main").on( "mouseenter", function(e){
        $(e.currentTarget).find(".left-arrow").show()
        $(e.currentTarget).find(".right-arrow").show()
    })

    $(".popular-main").on("mouseleave",function(){
        $(".left-arrow").hide()
        $(".right-arrow").hide()
    })

    $(".news-container").on( "mouseenter", function(e){
        $(e.currentTarget).find(".left-arrow").show()
        $(e.currentTarget).find(".right-arrow").show()
    })

    $(".news-container").on("mouseleave",function(){
        $(".left-arrow").hide()
        $(".right-arrow").hide()
    })


    // const cardForm = mp.cardForm({
    //   amount: "100.5",
    //   iframe: true,
    //   form: {
    //     id: "form-checkout",
    //     cardNumber: {
    //       id: "form-checkout__cardNumber",
    //       placeholder: "Numero de tarjeta",
    //     },
    //     expirationDate: {
    //       id: "form-checkout__expirationDate",
    //       placeholder: "MM/YY",
    //     },
    //     securityCode: {
    //       id: "form-checkout__securityCode",
    //       placeholder: "Código de seguridad",
    //     },
    //     cardholderName: {
    //       id: "form-checkout__cardholderName",
    //       placeholder: "Titular de la tarjeta",
    //     },
    //     issuer: {
    //       id: "form-checkout__issuer",
    //       placeholder: "Banco emisor",
    //     },
    //     installments: {
    //       id: "form-checkout__installments",
    //       placeholder: "Cuotas",
    //     },        
    //     identificationType: {
    //       id: "form-checkout__identificationType",
    //       placeholder: "Tipo de documento",
    //     },
    //     identificationNumber: {
    //       id: "form-checkout__identificationNumber",
    //       placeholder: "Número del documento",
    //     },
    //     cardholderEmail: {
    //       id: "form-checkout__cardholderEmail",
    //       placeholder: "E-mail",
    //     },
    //   },
    //   callbacks: {
    //     onFormMounted: error => {
    //       if (error) return console.warn("Form Mounted handling error: ", error);
    //       console.log("Form mounted");
    //     },
    //     onSubmit: event => {
    //       event.preventDefault();

    //       const {
    //         paymentMethodId: payment_method_id,
    //         issuerId: issuer_id,
    //         cardholderEmail: email,
    //         amount,
    //         token,
    //         installments,
    //         identificationNumber,
    //         identificationType,
    //       } = cardForm.getCardFormData();

    //       fetch("/discovery/process_payment", {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({
    //           token,
    //           issuer_id,
    //           payment_method_id,
    //           transaction_amount: Number(amount),
    //           installments: Number(installments),
    //           description: "Descripción del producto",
    //           payer: {
    //             email,
    //             identification: {
    //               type: identificationType,
    //               number: identificationNumber,
    //             },
    //           },
    //         }),
    //       });
    //     },
    //     onFetching: (resource) => {
    //       console.log("Fetching resource: ", resource);

    //       // Animate progress bar
    //       const progressBar = document.querySelector(".progress-bar");
    //       progressBar.removeAttribute("value");

    //       return () => {
    //         progressBar.setAttribute("value", "0");
    //       };
    //     }
    //   },
    // });

})
    

$(document).on("turbolinks:load",function () {
    $(function () {
        $(".preloader").fadeOut();
        // $('.side-menu-2').metisMenu();

        $(".navbar-toggle").click(function (e) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            $(".navbar-toggle i").toggleClass("ti-menu");
            $(".navbar-toggle i").addClass("ti-close");
            // $(".sidebar-nav").slideToggle();
            
        });

         
    });

    var notifications_params = $("#notifications_params").val()
    if(notifications_params === "true"){    
        $(".right-sidebar-notifications").slideDown(50)
        $(".right-sidebar-notifications").toggleClass("shw-rside")
    }




      // Notes update method
      $(".notes").on("blur",function(){
        var user_id = $("#user_id").val()
        var account_id = $("#account_id").val()
        $("#notes").append("<div class='mini-spinner'><i class='fa fa-spinner fa fa-spin fa fa-2x'></i></div>")
        $.ajax({
          url: "/account/" + account_id + "/users/" + user_id + "/update_notes",
          method: "POST",
          data: {notes: $(this).val()},
          success: function(){
            $("#notes").find(".mini-spinner").remove()
          }
        })
      })

    // Theme settings
    //Open-Close-right sidebar


    $(".notifications-btn").click(function(){
        $(".right-sidebar-notifications").slideDown(50)
        $(".right-sidebar-notifications").toggleClass("shw-rside")
    })
    $(".right-side-toggle").click(function () {
        $(".right-sidebar").slideDown(50);
        $(".right-sidebar").toggleClass("shw-rside");
        // Fix header
        $(".fxhdr").click(function () {
            $("body").toggleClass("fix-header");
        });
        // Fix sidebar
        $(".fxsdr").click(function () {
            $("body").toggleClass("fix-sidebar");
        });
        // Service panel js
        if ($("body").hasClass("fix-header")) {
            $('.fxhdr').attr('checked', true);
        }
        else {
            $('.fxhdr').attr('checked', false);
        }
        if ($("body").hasClass("fix-sidebar")) {
            $('.fxsdr').attr('checked', true);
        }
        else {
            $('.fxsdr').attr('checked', false);
        }
    });

    $(".notification-title").on("input",function(){
      $(".sc-cvbbAY").text($(this).val());
    })

    $(".notification-content").on("input",function(){
      $(".sc-jWBwVP").text($(this).val());
    })

    setTimeout(function(){ 
        $(".last-step-form").submit() 
    }, 2000);
    //Loads the correct sidebar on window load,
    //collapses the sidebar on window resize.
    // Sets the min-height of #page-wrapper to window size
    $(function () {
        $(window).bind("load resize", function () {
            let topOffset = 60;
            let width = (this.window.innerWidth > 0) ? this.window.innerWidth : this.screen.width;
            if (width < 768) {
                $('div.navbar-collapse').addClass('collapse');
                topOffset = 100; // 2-row-menu
            }
            else {
                $('div.navbar-collapse').removeClass('collapse');
            }
            let height = ((this.window.innerHeight > 0) ? this.window.innerHeight : this.screen.height) - 1;
            height = height - topOffset;
            if (height < 1) height = 1;
            if (height > topOffset) {
                $("#page-wrapper").css("min-height", (height) + "px");
            }
        });
        var url = window.location;
        var element = $('ul.nav a').filter(function () {
            return this.href == url || url.href.indexOf(this.href) == 0;
        }).addClass('active').parent().parent().addClass('in').parent();
        if (element.is('li')) {
            element.addClass('active');
        }
    });
    // This is for resize window
    $(function () {
        // $(window).bind("load resize", function () {
        //     width = (this.window.innerWidth > 0) ? this.window.innerWidth : this.screen.width;
        //     if (width < 1170) {
        //         $('body').addClass('content-wrapper');
        //         $(".open-close i").removeClass('icon-arrow-left-circle');
        //         $(".sidebar-nav, .slimScrollDiv").css("overflow-x", "visible").parent().css("overflow", "visible");
        //         $(".logo span").hide();
        //     }
        //     else {
        //         $('body').removeClass('content-wrapper');
        //         $(".open-close i").addClass('icon-arrow-left-circle');
        //         $(".logo span").show();
        //     }
        // });
    });
    // This is for click on open close button
    // Sidebar open close
    $(".open-close").on('click', function () {
        if ($("body").hasClass("content-wrapper")) {
            $("body").trigger("resize");
            $(".sidebar-nav, .slimScrollDiv").css("overflow", "hidden").parent().css("overflow", "visible");
            $("body").removeClass("content-wrapper");
            $(".open-close i").addClass("icon-arrow-left-circle");
            $(".logo span").show();
        }
        else {
            $("body").trigger("resize");
            $(".sidebar-nav, .slimScrollDiv").css("overflow-x", "visible").parent().css("overflow", "visible");
            $("body").addClass("content-wrapper");
            $(".open-close i").removeClass("icon-arrow-left-circle");
            $(".logo span").hide();
        }
    });
    // Collapse Panels
    (function ($, window, document) {
        var panelSelector = '[data-perform="panel-collapse"]';
        $(panelSelector).each(function () {
            var $this = $(this)
                , parent = $this.closest('.panel')
                , wrapper = parent.find('.panel-wrapper')
                , collapseOpts = {
                    toggle: false
                };
            if (!wrapper.length) {
                wrapper = parent.children('.panel-heading').nextAll().wrapAll('<div/>').parent().addClass('panel-wrapper');
                collapseOpts = {};
            }
            wrapper.collapse(collapseOpts).on('hide.bs.collapse', function () {
                $this.children('i').removeClass('ti-minus').addClass('ti-plus');
            }).on('show.bs.collapse', function () {
                $this.children('i').removeClass('ti-plus').addClass('ti-minus');
            });
        });
        $(document).on('click', panelSelector, function (e) {
            e.preventDefault();
            var parent = $(this).closest('.panel');
            var wrapper = parent.find('.panel-wrapper');
            wrapper.collapse('toggle');
        });
    }(jQuery, window, document));
    // Remove Panels
    (function ($, window, document) {
        var panelSelector = '[data-perform="panel-dismiss"]';
        $(document).on('click', panelSelector, function (e) {
            e.preventDefault();
            var parent = $(this).closest('.panel');
            removeElement();

            function removeElement() {
                var col = parent.parent();
                parent.remove();
                col.filter(function () {
                    var el = $(this);
                    return (el.is('[class*="col-"]') && el.children('*').length === 0);
                }).remove();
            }
        });
    }(jQuery, window, document));
    //tooltip
    $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
        //Popover
    $(function () {
            $('[data-toggle="popover"]').popover()
        })
        // Task
    $(".list-task li label").click(function () {
        $(this).toggleClass("task-done");
    });
    $(".settings_box a").click(function () {
        $("ul.theme_color").toggleClass("theme_block");
    });
});
//Colepsible toggle
$(".collapseble").click(function () {
    $(".collapseblebox").fadeToggle(350);
});
// Sidebar
$('.slimscrollright').slimScroll({
    height: '100%'
    , position: 'right'
    , size: "5px"
    , color: '#dcdcdc'
, });
$('.slimscrollsidebar').slimScroll({
    height: '100%'
    , position: 'right'
    , size: "0px"
    , color: '#dcdcdc'
, });
$('.chat-list').slimScroll({
    height: '100%'
    , position: 'right'
    , size: "0px"
    , color: '#dcdcdc'
, });
// Resize all elements
$("body").trigger("resize");
// visited ul li
$('.visited li a').click(function (e) {
    $('.visited li').removeClass('active');
    var $parent = $(this).parent();
    if (!$parent.hasClass('active')) {
        $parent.addClass('active');
    }
    e.preventDefault();
});
// Login and recover password
$('#to-recover').click(function () {
    $("#loginform").slideUp();
    $("#recoverform").fadeIn();
});
// Update 1.5
// this is for close icon when navigation open in mobile view

// Update 1.6