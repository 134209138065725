require("@rails/ujs").start()
require("turbolinks").start()
import 'jquery'
import 'src/slimscroll.min.js'

import 'src/custom.js'
import 'src/bootstrap.js'
import 'src/metismenu.min.js'
import 'src/select2.full.min.js'
import 'bootstrap/js/dist/carousel'

import toastr from 'toastr'
window.toastr = toastr

import 'bootstrap-datepicker'

console.log("discovery")
